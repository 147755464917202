import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksModuleStyles } from "./PerksModuleStyles"
import { MdOutlineClose as Cross } from "react-icons/md"
import Perk from "./Perk"

const PerksModule = () => {
  return (
    <PerksModuleStyles className="section section__padding">
      <StaticImage
        className="perks__image--bg"
        src="../../../static/arena-negra-el-salvador.jpg"
        alt="Perks Module"
        layout="constrained"
        placeholder="tracedSVG"
      />
      <div className="perks__image--overlay"></div>
      <div className="container container__tight">
        <Perk
          title="Clientes"
          content="¿Necesita un proveedor aprobado? Nuestro equipo ha trabajado 
          con varios departamentos gubernamentales. Contáctenos para obtener más 
          información sobre cómo nuestro equipo puede ayudar a llevar a cabo su proyecto."
        >
          <StaticImage
            src="../../../static/logos/gobierno-de-el-salvador-logo-dark-mode.png"
            alt="Gobierno de El Salvador"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </Perk>
        <span className="perks__divider">
          <Cross />
        </span>
        <Perk
          title="Clientes"
          content="¿Eres un negocio que necesita restauraciones? Contáctenos hoy 
          para obtener más información sobre cómo podemos ayudar a restaurar obras de 
          arte, antigüedades o productos de carpintería personalizados."
        >
          <StaticImage
            src="../../../static/logos/logo-roble-logo-dark-mode.svg"
            alt="Grupo Roble"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </Perk>
      </div>
    </PerksModuleStyles>
  )
}

export default PerksModule
