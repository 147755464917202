import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
import Features from "../components/Features/Features"
import LatestPosts from "../components/Post/LatestPosts"

const Index = () => {
  return (
    <>
      <Seo title="Inicio" />
      <Layout>
        <BannerModule
          title="Galeria de Arte Saravia"
          subTitle="Obras de Arte y Enmarcados Profesional. Espacio para artistas, coleccionistas, amantes del arte y público en general."
        />
        <BasicTextModule
          title="Enmarcados Profesional"
          content="Nuestra galería cuenta con un estudio propio donde producimos marcos con la máxima atención al detalle. Los 
          coleccionistas de arte nos confían la restauración de sus colecciones privadas y, desde entonces, también hemos 
          brindado servicios a corporaciones y entidades gubernamentales."
          link="/contacto"
          linkText="Solicitar Cotización"
        />
        <PerksModule>
          <Perk title="The Title" content="The content" />
        </PerksModule>
        <Features
          title="Obras de arte disponible en nuestra galería"
          introduction="Tenemos una gran galería de arte abierta al público con arte selecto disponible para comprar. Visítenos 
          y amplíe su colección privada con artistas de renombre nacional e internacional."
        />
        <LatestPosts
          title="Eventos en nuestra galería"
          introduction="Organice su próximo evento en nuestro espacio de galería, estacionamiento privado disponible."
        />
      </Layout>
    </>
  )
}

export default Index
